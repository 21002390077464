import { useCallback } from "react";

import usePlacesServices from "../components/hooks/usePlacesServices";
import { AutocompletePrediction } from "../types/gmaps";

const PLACES_OPTIONS = {
  componentRestrictions: { country: ["us", "ca", "gb", "fr", "ie"] },
  fields: ["address_components", "geometry", "icon", "name"],
  types: ["address"],
};

function noResults() {
  return [] as AutocompletePrediction[];
}

export default function useFetchLocationPredictions() {
  const { getServicesPromise } = usePlacesServices();
  return useCallback(
    async (query?: string) => {
      if (!query) {
        return noResults();
      }

      const services = await getServicesPromise();
      const service = services?.autocompleteService;

      return new Promise<AutocompletePrediction[]>((resolve) => {
        if (!service) {
          resolve(noResults());
          return;
        }
        service.getPlacePredictions(
          {
            ...PLACES_OPTIONS,
            input: query,
          },
          (results: AutocompletePrediction[] | null) => resolve(results || noResults()),
        );
      });
    },
    [getServicesPromise],
  );
}
