import { LocationsCreateRequest } from "@meterup/api";
import * as Sentry from "@sentry/react";

import type { GeolocationMap, PredictionFormatterResult } from "../types/gmaps";

const countriesWithStates = ["US", "CA"];

function mapToAddressObject(geolocationMap: GeolocationMap) {
  const streetNumber = geolocationMap.streetNumber?.longName || "";
  const route = geolocationMap.route?.longName || "";
  const premise = geolocationMap.premise?.longName || "";
  const subpremise = geolocationMap.subpremise?.longName || "";
  const countryIso2 = geolocationMap.country?.shortName || "";
  const city =
    geolocationMap.locality?.longName ||
    geolocationMap.political?.longName ||
    geolocationMap.postalTown?.shortName ||
    "";
  const postalCode = geolocationMap.postalCode?.longName || "";
  const establishment = geolocationMap.establishment?.longName || "";

  let state = "";
  if (countriesWithStates.includes(countryIso2) && geolocationMap.administrativeAreaLevel1?.shortName) {
    state = geolocationMap.administrativeAreaLevel1.shortName;
  }

  let address1 = "";
  let address2 = "";

  if (streetNumber || route) {
    address1 = `${streetNumber} ${route}`.trim();
  } else if (premise) {
    address1 = premise;
  } else if (establishment) {
    address1 = establishment;
  } else {
    Sentry.captureException(new Error("No address1 found"), {
      extra: {
        ...geolocationMap,
      },
    });
  }

  if (subpremise) {
    address2 = subpremise;
  }

  return {
    address1,
    address2,
    city,
    state,
    postalCode,
    countryIso2,
  };
}

export default function formatLocationParts(
  result: PredictionFormatterResult,
): LocationsCreateRequest {
  const { components, entry } = result;

  return {
    ...mapToAddressObject(components),
    latitude: entry.geometry.location.lat(),
    longitude: entry.geometry.location.lng(),
  };
}
