import React, { useEffect, useMemo } from "react";

import type { AxiosAPIError, UsersResponse } from "@meterup/api";
import { PortalOrigin } from "@meterup/api";
import { get } from "@meterup/api/src/axios";
import useISPContracts from "@meterup/api/src/billing/useISPContracts";
import { getConnectUrl, getDashboardUrl } from "@meterup/api/src/config";
import { useCompany } from "@meterup/api/src/hooks/useCompany";
import useGetCompanyConnections from "@meterup/api/src/hooks/useGetCompanyConnections";
import { Button, Text, VStack } from "@meterup/metric";
import { styled } from "@meterup/ui";
import LabelRow from "@meterup/ui/src/components/LabelRow";
import { TabItem } from "@meterup/ui/src/components/Tabs/Tabs";
import { TabsBar } from "@meterup/ui/src/components/Tabs/TabsBar";
import { TabsBarContainer } from "@meterup/ui/src/components/Tabs/TabsBarContainer";
import BaseDataView from "@meterup/ui/src/DataView/BaseDataView";
import { BaseDataViewWrapper } from "@meterup/ui/src/DataView/components/BaseDataViewWrapper";
import CompositeHeader from "@meterup/ui/src/DataView/components/CompositeHeader";
import Header from "@meterup/ui/src/DataView/components/Header";
import { logError } from "@meterup/ui/src/Log.utils";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useOutlet, useParams } from "react-router-dom";

import { H3 } from "../../styles/dashboard";

const CompanyWrapper = styled("div", {
  [`& ${LabelRow}`]: {
    paddingBottom: 0,
  },

  [`& ${BaseDataViewWrapper} ${CompositeHeader} ${TabsBarContainer}`]: {
    marginTop: "$8",
  },
});

const tabNames = ["users", "billing", "searches"] as const;

export default function Company() {
  const { companySID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = useMemo(() => {
    const parts = location.pathname.split("/").slice(-3);
    return tabNames.find((tabName) => parts.includes(tabName));
  }, [location.pathname]);
  const {
    data: contracts,
    isLoading: contractsLoading,
    isError: contractsIsError,
  } = useGetCompanyConnections({
    onError(e: any) {
      logError(e);
    },
  });
  const numContracts = useMemo(() => {
    if (contractsLoading || contractsIsError || !contracts) {
      return 0;
    }
    contracts.contracts.reduce((acc, contract) => acc + contract.companyContracts.length, 0);
    return contracts?.contracts.length;
  }, [contracts, contractsIsError, contractsLoading]);
  const { data: users } = useQuery<unknown, AxiosAPIError, UsersResponse>(
    ["companyUsers", companySID],
    () => get<UsersResponse>(`v1/companies/${companySID}/users`),
    {
      refetchOnWindowFocus: false,
    },
  );
  const { data: contractsResponse } = useISPContracts();
  const { data: company } = useCompany(companySID);
  const companyContext = useMemo(
    () => ({ allContracts: contracts, company, contracts: contractsResponse, users }),
    [company, contracts, contractsResponse, users],
  );
  const children = useOutlet(companyContext);

  useEffect(() => {
    if (children === null) {
      navigate(`/dashboard/companies/${companySID}/searches`, {
        replace: true,
      });
    }
  }, [children, companySID, navigate]);

  return (
    <CompanyWrapper>
      <BaseDataView
        buttons={
          <Button variant="tertiary">
            <a
              href={getDashboardUrl(
                window.location,
                PortalOrigin.Connect,
                `/org/${company?.slug}/connect/locations`,
              )}
              target="_blank"
              rel="noreferrer">
              Open dashboard
            </a>
          </Button>
        }
        header={
          <CompositeHeader>
            <VStack>
              <Header>
                <Text
                  size={20}
                  lineHeight={28}
                  as={H3}
                  color={{ light: "gray-800" }}
                  weight="medium">
                  {company?.name}
                </Text>
              </Header>
              <TabsBar>
                <TabItem
                  path={`/dashboard/companies/${companySID}/searches`}
                  count={numContracts}
                  isActive={activeTab === "searches"}>
                  Searches
                </TabItem>
                <TabItem
                  path={`/dashboard/companies/${companySID}/users`}
                  count={users ? users.users.length : 0}
                  isActive={activeTab === "users"}>
                  Users
                </TabItem>
                <TabItem
                  path={`/dashboard/companies/${companySID}/billing`}
                  count={contractsResponse ? contractsResponse.contracts.length : 0}
                  isActive={activeTab === "billing"}>
                  Billing
                </TabItem>
              </TabsBar>
            </VStack>
          </CompositeHeader>
        }>
        {children}
      </BaseDataView>
    </CompanyWrapper>
  );
}
