/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Location, Currency, FileAttachmentInfo } from "./common";

export const protobufPackage = "connect";

/** LocationProviderAvailability */
export enum Availability {
  location_on_net = "location_on_net",
  location_near_net = "location_near_net",
  location_off_net = "location_off_net",
}

export enum ConnectionChangeStatus_StatusType {
  QUOTES = "QUOTES",
  CONFIRM_QUOTES = "CONFIRM_QUOTES",
  CONTRACT_REQUESTED = "CONTRACT_REQUESTED",
}

export enum ConnectionChangeStatus_StatusCategory {
  QUOTES_CATEGORY = "QUOTES_CATEGORY",
}

export enum InvoiceStatus {
  unpaid = "unpaid",
  paid = "paid",
  past_due = "past_due",
  cancelled = "cancelled",
  upcoming = "upcoming",
  refunded = "refunded",
}

export interface QuoteRequest {
  sid: string;
  status: QuoteRequest_Status;
  company_name: string;
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
  requested_download_kbps: number;
  contract_minimum_months: number;
  contract_maximum_install_date?: string;
  notes: string;
  expired_at?: string;
  created_at?: string;
  selected_quotes: string[];
  exports: QuoteRequestExport[];
  requested_upload_kbps: number;
  is_manual_response_pending: boolean;
  is_interested_in_network: boolean;
  location?: Location;
  existing_provider?: Provider;
  existing_contract_end_date?: string;
  existing_monthly_fee_cents: number;
}

export enum QuoteRequest_Status {
  unknown = "unknown",
  created = "created",
  ready_for_review = "ready_for_review",
  contract_requested = "contract_requested",
}

/** POST /connect/quote-requests */
export interface QuoteRequestCreateRequest {
  company_name: string;
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
  requested_download_kbps: number;
  contract_minimum_months: number;
  contract_maximum_install_date?: string;
  notes: string;
  requested_upload_kbps: number;
  location?: LocationsCreateRequest;
  existing_provider_sid: string;
  existing_contract_end_date?: string;
  existing_monthly_fee_cents: number;
}

/** PUT /connect/quote-requests/{id} */
export interface QuoteRequestUpdateRequest {
  status: QuoteRequestUpdateRequest_Status;
  contact_name: string;
  contact_email: string;
  contact_telephone: string;
  requested_download_kbps: number;
  contract_minimum_months: number;
  contract_maximum_install_date?: string;
  notes: string;
  selected_quotes: string[];
  /**
   * True if the selected_quote field's value represents a genuine change from
   * its previous state (vs. an empty array).
   */
  selected_quotes_changed: boolean;
  expired_at?: string;
  requested_upload_kbps: number;
  is_manual_response_pending: boolean;
  is_manual_response_pending_changed: boolean;
  is_interested_in_network: boolean;
  is_interested_in_network_changed: boolean;
  company_name: string;
  location?: LocationsUpdateRequest;
  existing_provider_sid: string;
  existing_contract_end_date?: string;
  existing_monthly_fee_cents: number;
}

export enum QuoteRequestUpdateRequest_Status {
  unknown = "unknown",
  archived = "archived",
  contract_requested = "contract_requested",
}

/** GET /api/connect/quote-requests */
export interface QuoteRequestsRequest {
  quote_requests: QuoteRequest[];
}

/** POST /api/connect/quote-requests/{quote_request_id}/locations */
export interface LocationsCreateRequest {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  country_iso2: string;
}

/** PATCH /api/connect/quote-requests/{quote_request_id}/locations/{sublocationSid} */
export interface LocationsUpdateRequest {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  country_iso2: string;
}

export interface Quote {
  sid: string;
  provider?: Provider;
  connection_type?: ConnectionType;
  recommended: boolean;
  status: Quote_Status;
  monthly_fee_cents: number;
  install_fee_cents: number;
  contract_length_months: number;
  download_kbps: number;
  upload_kbps: number;
  installation_estimate_days: number;
  riser_responsibility: Quote_RiserResponsibility;
  notes: string;
  router_included: boolean;
  is_manual: boolean;
  availability: Quote_Availability;
  quote_request_sid: string;
}

export enum Quote_Status {
  disabled = "disabled",
  enabled = "enabled",
  proposed = "proposed",
  requested = "requested",
}

export enum Quote_RiserResponsibility {
  provider_responsibility = "provider_responsibility",
  customer_responsibility = "customer_responsibility",
  unknown_responsibility = "unknown_responsibility",
}

export enum Quote_Availability {
  unknown = "unknown",
  location_on_net = "location_on_net",
  location_near_net = "location_near_net",
  location_off_net = "location_off_net",
}

/** POST /api/connect/quote-requests/{quote_request_id}/quotes */
export interface QuotesCreateRequest {
  provider: string;
  connection_type: string;
  recommended: boolean;
  status: QuotesCreateRequest_Status;
  monthly_fee_cents: number;
  install_fee_cents: number;
  contract_length_months: number;
  download_kbps: number;
  upload_kbps: number;
  installation_estimate_days: number;
  riser_responsibility: QuotesCreateRequest_RiserResponsibility;
  notes: string;
  router_included: boolean;
}

export enum QuotesCreateRequest_Status {
  unknown = "unknown",
  disabled = "disabled",
  enabled = "enabled",
  proposed = "proposed",
  requested = "requested",
}

export enum QuotesCreateRequest_RiserResponsibility {
  unknown_responsibility = "unknown_responsibility",
  provider_responsibility = "provider_responsibility",
  customer_responsibility = "customer_responsibility",
}

/** PUT /api/connect/quote-requests/{quote_request_id}/quotes/{id} */
export interface QuotesUpdateRequest {
  provider: string;
  connection_type: string;
  recommended: boolean;
  status: QuotesUpdateRequest_Status;
  monthly_fee_cents: number;
  install_fee_cents?: number;
  contract_length_months: number;
  download_kbps: number;
  upload_kbps: number;
  installation_estimate_days: number;
  riser_responsibility: QuotesUpdateRequest_RiserResponsibility;
  notes: string;
  router_included: boolean;
  /**
   * True if the respective field's value represents a genuine change from its
   * previous state (vs. unset)
   */
  recommended_changed: boolean;
  router_included_changed: boolean;
}

export enum QuotesUpdateRequest_Status {
  unknown = "unknown",
  disabled = "disabled",
  enabled = "enabled",
  proposed = "proposed",
  requested = "requested",
}

export enum QuotesUpdateRequest_RiserResponsibility {
  unknown_responsibility = "unknown_responsibility",
  provider_responsibility = "provider_responsibility",
  customer_responsibility = "customer_responsibility",
}

/** GET /api/connect/quote-requests/{quote_request_id}/quotes */
export interface QuotesRequest {
  quotes: Quote[];
}

export interface Sender {
  sid: string;
  name: string;
  avatar_url: string;
}

export interface Message {
  sid: string;
  sender?: Sender;
  message: string;
  system: boolean;
  created_at?: string;
  updated_at?: string;
}

/** POST /api/connect/quote-requests/{quote_request_id}/messages */
export interface MessagesCreateRequest {
  /** Sender.sid */
  sender: string;
  message: string;
  system: boolean;
}

/** PATCH /api/connect/quote-requests/{quote_request_id}/messages/{id} */
export interface MessagesUpdateRequest {
  /** Sender.sid */
  sender: string;
  message: string;
  system: boolean;
}

/** GET /api/connect/quote-requests/{quote_request_id}/messages */
export interface MessagesRequest {
  messages: Message[];
}

/**
 * Deprecated, use the Provider in api.proto instead. However, because there are
 * some other objects that reference this - for example it's present on a Quote
 * - we might add new properties here as they get added to the api.proto
 * property.
 *
 * We still need to work out the best way to lay out the protobuf dependencies
 * and which packages make sense.
 */
export interface Provider {
  sid: string;
  name: string;
  /** E.g. "/providers/sonic.png" */
  path: string;
}

export interface ConnectionType {
  sid: string;
  name: string;
}

/** POST /v1/connect/providers body */
export interface ProviderCreateRequest {
  name: string;
}

/**
 * GET /v1/connect/providers
 * Deprecated: Use ProvidersResponse instead
 */
export interface ProvidersRequest {
  providers: Provider[];
}

/** GET /v1/connect/providers */
export interface ProvidersResponse {
  providers: Provider[];
}

/** GET /api/connect/connectionTypes */
export interface ConnectionTypesRequest {
  connection_types: ConnectionType[];
}

export interface QuoteRequestExport {
  vendor: QuoteRequestExport_Vendor;
  status: QuoteRequestExport_Status;
  job_id: string;
  foreign_id: string;
  completed_at?: string;
  error_message: string;
  sid: string;
}

export enum QuoteRequestExport_Status {
  unknown_status = "unknown_status",
  running = "running",
  success = "success",
  error = "error",
}

export enum QuoteRequestExport_Vendor {
  unknown_vendor = "unknown_vendor",
  telarus = "telarus",
  zayo = "zayo",
  bandwidth_finder = "bandwidth_finder",
  easyquote = "easyquote",
}

export interface QuoteRequestExports {
  quote_request_exports: QuoteRequestExport[];
}

/** POST /api/connect/quote-requests/{sid}/exports/zayo */
export interface QuoteRequestExportZayoRequest {
  /** Zayo's location ID, not ours */
  location_id: string;
}

/** GET /v1/connect/quote-requests/{id}/integrations/zayo/buildings */
export interface ZayoBuilding {
  matched_address: string;
  building_id: string;
  zayo_network_status: string;
}

export interface ZayoGetBuildings {
  buildings: ZayoBuilding[];
}

/** GET /v1/connect/quote-requests/{id}/integrations/zayo/buildings/{id}/locations */
export interface ZayoLocation {
  location_id: string;
  name: string;
  zayo_network_status: string;
}

export interface ZayoGetLocations {
  locations: ZayoLocation[];
}

/** POST /v1/connect/admin/location-provider-availabilities */
export interface LocationProviderAvailabilityUpsertRequest {
  location_sid: string;
  provider_sid: string;
  availability: Availability;
}

/** DELETE /v1/connect/admin/location-provider-availabilities */
export interface LocationProviderAvailabilityDeleteRequest {
  location_sid: string;
  provider_sid: string;
}

export interface ProviderAvailability {
  provider_name: string;
  availability: Availability;
  provider_sid: string;
}

/** GET /v1/connect/admin/location-provider-availabilities/locations/:locationSID */
export interface ProviderAvailabilitiesResponse {
  providerAvailabilities: ProviderAvailability[];
}

/** PUT /v1/companies/:company-slug/locations/:company-location-sid */
export interface CompanyLocationPutRequest {
  name?: string;
}

export interface ConnectionStatus {
  sid: string;
  status: string;
  display: string;
  category: string;
  priority: number;
  status_category: ConnectionChangeStatus_StatusCategory;
  status_type: ConnectionChangeStatus_StatusType;
}

/** GET /v1/connect/admin/statuses */
export interface ConnectionStatusesResponse {
  statuses: ConnectionStatus[];
}

/** PUT /v1/companies/:slug/internet-service-plans/:sid */
export interface UpdateInternetServicePlanNotesRequest {
  notes?: string;
}

export interface ISPContract {
  sid: string;
  internet_service_plan_sid: string;
  contract_start_date?: string;
  contract_end_date?: string;
  amount_due_currency: Currency;
  amount_due: number;
  document?: FileAttachmentInfo | undefined;
  first_invoice_due_date?: string;
}

export interface Invoice {
  sid: string;
  isp_contracts_sid: string;
  due_date?: string;
  amount_actual_currency: Currency;
  amount_actual: number;
  status: InvoiceStatus;
  billing_cycle_start_date?: string;
  billing_cycle_end_date?: string;
  document?: FileAttachmentInfo | undefined;
  amount_currency?: Currency | undefined;
  amount?: number | undefined;
  invoiced_at?: string;
  paid_at?: string;
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
